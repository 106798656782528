import { CreateControllerFn } from '@wix/yoshi-flow-editor';

import { WixGroupsController } from '../../controller';
import { Resolvables, waitForTransition } from 'router/helpers';

import { setupRouter } from './router';

const createController: CreateControllerFn = async (params) => {
  const { isViewer } = params.flowAPI.environment;

  const controller = new WixGroupsController(params);

  controller.handleTransition = handleTransition;

  setupRouter(params, controller.vm, controller.router, controller.store);

  controller.router.urlService.rules.initial({ state: 'groups.list' });
  controller.router.urlService.rules.otherwise({ state: 'groups.list' });

  return controller;

  async function handleTransition(resolvables?: Resolvables) {
    return waitForTransition(controller.router, {
      resolvables,
      to: 'groups.list',
      disableWaitForResolvables: !isViewer,
      blacklist: ['comments', 'commentsApi'],
    });
  }
};

export default createController;
